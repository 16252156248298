<template>
  <b-collapse class="panel" animation="slide">
    <template #trigger="props">
      <div class="panel-heading">
        <h2>{{ $tc("product", 2) }}</h2>
        <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'"></b-icon>
      </div>
    </template>
    <div class="panel-block">
      <b-table :data="products" default-sort="company.name" :loading="loading">
        <template #empty>
          {{ loading ? $t("loading") : $t("no_matching_results") }}
        </template>

        <b-table-column :label="$t('name')" field="name" sortable>
          <template v-slot="props">
            {{ props.row.name }}
          </template>
        </b-table-column>

        <b-table-column numeric>
          <template v-slot="props">
            <b-checkbox
              :value="allowedProducts.includes(props.row['@id'])"
              @input="(e) => updateAllowedProducts(props.row, e)"
            ></b-checkbox>
          </template>
        </b-table-column>
      </b-table>
    </div>
  </b-collapse>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      products: [],
      allowedProducts: this.user.allowedProducts.map((p) => p["@id"]),
    };
  },
  mounted() {
    this.getAllProducts().then((products) => {
      this.products = products;
      this.loading = false;
    });
  },
  methods: {
    ...mapActions(["getAllProducts", "updateUser"]),
    async updateAllowedProducts(product, allowed) {
      if (allowed) {
        this.allowedProducts.push(product["@id"]);
      } else {
        const i = this.allowedProducts.indexOf(product["@id"]);
        this.allowedProducts.splice(i, 1);
      }

      this.loading = true;

      this.updateUser({
        id: this.user.id,
        user: { allowedProducts: this.allowedProducts },
      }).then(async (user) => {
        // API will sometimes return an array and sometimes an object
        // so make sure to cast response into an array
        this.allowedProducts = Object.values(user.allowedProducts).map(
          (p) => p["@id"]
        );

        this.loading = false;
      });
    },
  },
};
</script>
