<template>
  <div>
    <b-loading v-model="loading"></b-loading>

    <div
      v-if="!loading"
      class="is-flex is-flex-wrap-wrap is-align-items-center my-4"
    >
      <h1 class="mr-5">
        <span>{{ $tc("user", 2) }} &ndash;</span>
        {{ user.emailAddress }}
      </h1>
      <b-button
        v-if="userHasRoleSuperAdmin"
        type="is-primary"
        icon-left="pencil"
        tag="router-link"
        :to="{ name: 'UserEdit', params: { id: $route.params.id } }"
      >
        {{ $t("edit") }}
      </b-button>
      <b-button
        v-if="!user.roles.includes('ROLE_SUPERADMIN')"
        type="is-danger"
        icon-left="delete"
        class="ml-2"
        @click="remove"
      >
        {{ $t("delete") }}
      </b-button>
    </div>

    <div v-if="!loading" class="card">
      <div class="card-content pt-3">
        <BackButton :parent-route="{ name: 'UserIndex' }"></BackButton>

        <table class="table details-table">
          <tr>
            <th>{{ $t("name") }}</th>
            <td>{{ user.name }}</td>
          </tr>
          <tr>
            <th>{{ $t("email") }}</th>
            <td>
              <a :href="`mailto:${user.emailAddress}`">
                {{ user.emailAddress }}
              </a>
            </td>
          </tr>
          <tr>
            <th>{{ $t("phone") }}</th>
            <td>
              <a :href="`tel:${user.phoneNumber}`">
                {{ user.phoneNumber }}
              </a>
            </td>
          </tr>
          <tr>
            <th>{{ $tc("language", 1) }}</th>
            <td>{{ user.language.isoName }}</td>
          </tr>
          <tr v-if="userHasRoleSuperAdmin">
            <th>{{ $tc("role") }}</th>
            <td>{{ user.roles | highestUserRole }}</td>
          </tr>
          <tr v-if="!userHasRoleSuperAdmin">
            <th>{{ $t("role_company") }}</th>
            <td>
              {{ companyUser.roles | highestCompanyRole }}<br />
              <b-button
                icon-left="pencil"
                class="mt-1"
                @click="showRoleModal = true"
              >
                {{ $t("edit") }}
              </b-button>
            </td>
          </tr>
          <tr>
            <th>{{ $t("password") }}</th>
            <td>
              <b-button icon-left="email" class="mt-1" @click="resetPassword">
                {{ $t("password_reset") }}
              </b-button>
            </td>
          </tr>
        </table>

        <Companies v-if="userHasRoleSuperAdmin" :user="user"></Companies>

        <Products
          v-if="userHasRoleSuperAdmin"
          :user="user"
          class="mt-5"
        ></Products>
      </div>
    </div>

    <b-modal v-model="showRoleModal" :width="400" scroll="keep">
      <CompanyUserRoleModal
        :company-user="companyUser"
        @submit="load"
      ></CompanyUserRoleModal>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import BackButton from "../../components/BackButton";
import Companies from "./partials/Companies";
import CompanyUserRoleModal from "./partials/CompanyUserRoleModal.vue";
import Products from "./partials/Products";

export default {
  name: "UserView",
  components: {
    BackButton,
    Companies,
    CompanyUserRoleModal,
    Products,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      showRoleModal: false,
      user: null,
      companyUser: null,
    };
  },
  computed: {
    ...mapGetters(["userHasRoleSuperAdmin"]),
    ...mapState({
      entity: (state) => state.auth.entity,
    }),
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["deleteUser", "getUser", "resetPasswordRequest"]),
    load() {
      this.getUser(this.id).then(async (user) => {
        this.user = user;
        this.companyUser = this.user.companies.find(
          (cu) => cu.company === this.entity["@id"]
        );
        this.loading = false;
      });
    },
    remove() {
      this.$buefy.dialog.confirm({
        title: this.$t("user_delete"),
        message: this.$t("delete_confirm"),
        cancelText: this.$t("cancel"),
        confirmText: this.$t("yes"),
        type: "is-danger",
        onConfirm: () => {
          this.loading = true;

          this.deleteUser(this.user.id)
            .then(() => {
              this.loading = false;

              this.$buefy.toast.open({
                message: this.$t("user_deleted"),
                type: "is-primary",
              });
              this.$router.push({ name: "UserIndex" });
            })
            .catch(() => {
              this.loading = false;
            });
        },
      });
    },
    resetPassword() {
      this.loading = true;

      this.resetPasswordRequest({ emailAddress: this.user.emailAddress })
        .then(() => {
          this.$buefy.toast.open({
            message: this.$t("password_reset_sent"),
            type: "is-primary",
          });
        })
        .catch((error) => {
          if (error.message === "TOO_MANY_ATTEMPTS") {
            this.$buefy.toast.open({
              message: this.$t("too_many_attempts"),
              type: "is-danger",
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
