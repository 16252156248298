<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        {{ $t("role_change") }}
      </div>
    </div>
    <div class="card-content">
      <form class="mt-2" @submit.prevent="submit">
        <b-field :label="$tc('role')" label-for="role">
          <b-select
            id="role"
            v-model="role"
            :disabled="onlyAdmin"
            :loading="loading"
            required
          >
            <option
              v-for="r in roles"
              :key="r.role"
              :value="r.role"
              :disabled="r.disabled"
            >
              {{ $t(r.role) }}
              {{ r.disabled ? ` (${$t("limit_reached")})` : "" }}
            </option>
          </b-select>
          <small v-if="onlyAdmin">{{ $t("only_admin") }}</small>
        </b-field>

        <b-button
          type="is-primary"
          native-type="submit"
          class="mt-5"
          icon-left="content-save"
          :loading="submitting"
        >
          {{ $t("save") }}
        </b-button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: {
    companyUser: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      submitting: false,
      roles: [
        { role: "ROLE_COMPANY_USER" },
        { role: "ROLE_COMPANY_ADMIN", disabled: true },
      ],
      role: this.companyUser.roles.includes("ROLE_COMPANY_ADMIN")
        ? "ROLE_COMPANY_ADMIN"
        : "ROLE_COMPANY_USER",
      onlyAdmin: false,
    };
  },
  computed: {
    ...mapState({
      entity: (state) => state.auth.entity,
    }),
  },
  async mounted() {
    const companyUsers = await this.getAllCompanyUsers(this.entity.id);

    // Only allow company admin role when limit (2) not reached
    const admins = companyUsers.filter(
      (cu) => !cu.blocked && cu.roles.includes("ROLE_COMPANY_ADMIN")
    ).length;
    if (admins < 2) {
      this.roles.find((r) => r.role === "ROLE_COMPANY_ADMIN").disabled = false;
    }

    // Disable role change when company user is the only company admin
    if (admins === 1 && this.companyUser.roles.includes("ROLE_COMPANY_ADMIN")) {
      this.onlyAdmin = true;
    }

    this.loading = false;
  },
  methods: {
    ...mapActions(["getAllCompanyUsers", "updateCompanyUser"]),
    submit() {
      this.submitting = true;

      this.updateCompanyUser({
        id: this.companyUser.id,
        companyUser: {
          roles: [this.role],
        },
      })
        .then(() => {
          this.$emit("submit");
          this.$parent.close();
        })
        .catch(() => {
          this.submitting = false;
        });
    },
  },
};
</script>
