<template>
  <b-collapse class="panel" animation="slide">
    <template #trigger="props">
      <div class="panel-heading">
        <h2>{{ $tc("company", 2) }}</h2>
        <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'"></b-icon>
      </div>
    </template>
    <div class="panel-block">
      <b-table
        :data="companyUsers"
        default-sort="company.name"
        :loading="loading"
        :row-class="(row) => row.blocked && 'has-text-grey-light'"
      >
        <template #empty>
          {{ loading ? $t("loading") : $t("no_matching_results") }}
        </template>

        <b-table-column :label="$tc('company')" field="company.name" sortable>
          <template v-slot="props">
            {{ props.row.company.name }}
          </template>
        </b-table-column>

        <b-table-column :label="$tc('role')" field="roles" sortable>
          <template v-slot="props">
            {{ props.row.roles | highestCompanyRole }}
          </template>
        </b-table-column>

        <b-table-column numeric>
          <template v-slot="props">
            <b-button
              v-if="!loading"
              icon
              size="is-small"
              type="is-text"
              tag="router-link"
              :to="{
                name: 'CompanyView',
                params: { id: props.row.company.id },
              }"
            >
              <b-icon icon="arrow-right" size="is-small"></b-icon>
            </b-button>
          </template>
        </b-table-column>
      </b-table>
    </div>
  </b-collapse>
</template>

<script>
import { mapActions } from "vuex";
import API from "../../../helpers/API";

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      companyUsers: [],
    };
  },
  async mounted() {
    for (const cu of this.user.companies) {
      const companyUser = { ...cu };
      companyUser.company = await this.getCompany(API.stripId(cu.company));
      this.companyUsers.push(companyUser);
    }
    this.loading = false;
  },
  methods: {
    ...mapActions(["getCompany"]),
  },
};
</script>
